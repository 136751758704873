import {createContext} from 'react';

import {User} from '@workos-inc/node';

import {AppAbilities} from '@src/library/permissions';

export interface SessionContextProps {
  user?: User;
  organization?: {
    id: string;
    name: string;
  };
  subdomain?: string;
  permissions?: AppAbilities;
}

const SessionContext = createContext<SessionContextProps>({});

export default SessionContext;
